<template>
  <v-container id="assets" fluid tag="section">
    <v-row>
      <v-col cols="12" class="pb-6">
        <file-input
          height="115px"
          :loading="uploadingImage"
          @change="uploadFileHandle"
        />
      </v-col>
    </v-row>
    <v-row v-if="uploadingImage">
      <v-col cols="12" class="py-6">
        <loading-files
          :file-name="uploadFileName"
          :value="fileUploadProgress"
          :cancel-function="source ? source.cancel : () => {}"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-6">
        <asset-list @loading="assetListLoading = $event" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-6">
        <unsplash-search-photos @loading="unsplashLoading = $event" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { modeTypes } from "@/lib/calendesk-js-library/components/assets/assetsTypes";
import uploadFileActions from "@/lib/calendesk-js-library/mixins/uploadFileActions";

export default {
  name: "ImageAssets",
  mixins: [uploadFileActions],
  mounted() {
    this.setMode(modeTypes.NONE);
  },
};
</script>
